import React from 'react';
import UploadDropZone from '../../../../../../common/DropZone/UploadDropZone';
import { GameEngineType } from '../../../../../../common/domain/game';
import { UploadedFile } from '../../../../../../common/domain/upload';

const MAX_GAME_FILE_SIZE = 500;

export function noFilesTextForGameFilesUpload(type: GameEngineType | undefined): string {
  switch (type) {
    case 'UNITY_5_5':
    case 'UNITY_5_4':
      return 'Upload the "Release" folder. Also upload the "StreamingAssets" folder if you are using it. You can drag & drop folders here.';

    case 'UNITY_5_6':
    case 'UNITY_2017':
    case 'UNITY_2018':
    case 'UNITY_2019':
    case 'UNITY_2020':
    case 'UNITY_2021':
    case 'UNITY_2022':
    case 'UNITY_2023':
    case 'UNITY_6':
      return 'Upload the "Build" folder. Also upload the "StreamingAssets" folder if you are using it. You can drag & drop folders here.';
    default:
      return 'Upload all game files. Make sure you have "index.html" as entry point for your game. You can drag & drop folders here.';
  }
}

export interface GameFilesUploadProps {
  gameType?: GameEngineType;
  initialFiles?: UploadedFile[];
  onFilesModified: (files: UploadedFile[]) => void;
  onUploadsStarted?: () => void;
  onAllUploadsComplete?: () => void;
  error?: boolean;
  isNonEditable?: boolean;
}

export const GameFilesUpload: React.FC<GameFilesUploadProps> = (props) => {
  const { initialFiles, gameType, onFilesModified, onAllUploadsComplete, onUploadsStarted, error, isNonEditable } = props;
  const dropDisabled = !gameType;
  const noFilesText = dropDisabled ? 'Select a game type first' : noFilesTextForGameFilesUpload(gameType);
  return (
    <div style={{ height: 200, width: '100%' }}>
      <UploadDropZone
        dropBehaviour="APPEND"
        dropDisabled={dropDisabled || isNonEditable}
        noFilesText={noFilesText}
        onFilesModified={(files) => onFilesModified(files)}
        initialFiles={initialFiles}
        maxFileSize={MAX_GAME_FILE_SIZE}
        onAllUploadsComplete={onAllUploadsComplete}
        onUploadsStarted={onUploadsStarted}
        error={error}
        isNonEditable={isNonEditable}
      />
    </div>
  );
};
