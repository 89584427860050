import { gql } from '@apollo/client';
import { mutate } from '../../../../../../common/graphql';
import { UploadedFileInput } from '../../../../../../common/graphql/upload-input';
import { SubmissionUnity56Encoding } from '../submission-graphql';
import { GraphGameEngineType } from '../../../../../../common/domain/game';
import { QAResult } from '../../QATool/components/SubmitManager';
import { GAME_ORIENTATION } from '../../../../../../common/CzyForm/OrientationSelector';
import { GameCoverWithUploadedFileInput } from '../../../../../../common/graphql/games/game';
import { SubmissionAPSDetail } from '../../SubmitGame/submit-game-graphql';

const UPDATE_SUBMISSION = gql`
  mutation updateSubmission($input: DeveloperSubmissionUpdateInput!) {
    developerSubmissionUpdate(input: $input) {
      id
      filesChanged
      requiresDevQaCheck
      coverChanged
      iframeLinkChanged
      qaResult {
        unfulfilledMainRequirements
        SDKImplemented
        SDKFeatures
        failedSDKFeatures
      }
    }
  }
`;

export interface UpdateSubmissionMutation {
  developerSubmissionUpdate: UpdateSubmissionResult;
}

export interface UpdateSubmissionResult {
  id: string;
  filesChanged?: boolean;
  coverChanged?: boolean;
  iframeLinkChanged?: boolean;
  qaResult?: QAResult;
  requiresDevQaCheck?: boolean;
}

export interface DeveloperSubmissionUpdateInput {
  id: string;
  gameFiles: UploadedFileInput[];
  gameCovers: GameCoverWithUploadedFileInput[];
  unity56Encoding?: SubmissionUnity56Encoding;

  gameName: string;
  categoryId: string;
  tagIds: string[];
  description: string;
  controls: string;

  iframeLink?: string;
  fullscreenable: boolean;
  isIOSFriendly: boolean;
  isAndroidFriendly: boolean;
  orientation?: GAME_ORIENTATION;
  steamStoreLink: string;
  appStoreLink: string;
  playStoreLink: string;
  playStoreDownloads?: number;
  appStoreDownloads?: number;
  steamDownloads?: number;
  hasIAP: boolean;
  isChromebookFriendly: boolean;

  gameEngineType: GraphGameEngineType;
  apsDetail: SubmissionAPSDetail;
}

export function updateSubmission(input: DeveloperSubmissionUpdateInput) {
  return mutate<DeveloperSubmissionUpdateInput, UpdateSubmissionMutation>(UPDATE_SUBMISSION, input);
}
