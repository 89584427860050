import React from 'react';
import { Alert, Checkbox, FormControlLabel } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { GameFilesUpload } from './GameFilesUpload';
import { UploadedFile } from '../../../../../../common/domain/upload';
import { UploadInstructions } from './UploadInstructions';
import TypeSelect from './TypeSelect';
import {
  FilesFormData,
  MAX_TOTAL_FILES_COUNT,
  MAX_TOTAL_FILE_SIZE_MB,
  ProgressSaveType,
  TOTAL_FILE_COUNT_WARNING,
  TOTAL_FILE_SIZE_DESKTOP_WARNING_MB,
  TOTAL_FILE_SIZE_MOBILE_WARNING_MB,
  validateFileLimitsSoft,
} from './FilesFormData';
import { GameEngineType } from '../../../../../../common/domain/game';
import OrientationSelector, { GAME_ORIENTATION } from '../../../../../../common/CzyForm/OrientationSelector';
import { StyledBodyText, StyledHeaderText } from '../../../../../../common/Styleguide/Common/Text';
import { StyledButton } from '../../../../../../common/Styleguide/Common/Button';
import { StyledInput } from '../../../../../../common/Styleguide/Common/Input';
import withTheme, { WithTheme } from '../../../../../helpers/WithTheme';
import { COLORS } from '../../../../../../common/Styleguide/Common/colors';
import { StyledContainer } from '../../../../../../common/Styleguide/Common/Container';
import { Add, Remove } from '@mui/icons-material';
import UnityCompressionOption from './UnityEngineOptions/UnityCompressionOption';
import GameDataSaveOptions from './GameDataSaveOptions';
import UnitySaveFileName from './UnityEngineOptions/UnitySaveFileName';
import { DOCS_URL } from '../../../../SideMenu/Menu';
import { GAME_COVERS_RESOLUTIONS, GameCoversWithUploadedFile } from '../../../../../../common/graphql/games/game';
import SubmissionUpdateGameCovers from '../../../Admin/Submission/UpdateSubmission/SubmissionUpdateGameCovers';
import withSubmission, { WithSubmission } from '../Context/withSubmission';
import { valueFromInput } from '../../../../../../common/input';
import { Link } from 'react-router-dom';
import GameMultiplayerOptions from './GameMultiplayerOptions';

interface Props {}

type _Props = Props & WithTheme & WithSubmission;

interface State {
  instructionsDialogOpen: boolean;
  isCollapsed: boolean;
}

export function renderSoftWarning(text: string | JSX.Element) {
  return (
    <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="warning">
      {text}
    </StyledBodyText>
  );
}
export function renderHardWarning(text: string | JSX.Element) {
  return (
    <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
      {text}
    </StyledBodyText>
  );
}

class GameFilesForm extends React.Component<_Props, State> {
  constructor(props: _Props) {
    super(props);
    this.state = {
      instructionsDialogOpen: false,
      isCollapsed: this.props.isNonEditable,
    };
  }

  render() {
    const { isReleased, isNonEditable } = this.props;
    const { isCollapsed } = this.state;
    return (
      <>
        <StyledContainer
          sx={{
            width: 900,
            m: 2,
            mt: 3,
            p: 2,
            px: 3,
          }}
        >
          {isReleased ? (
            this.renderReleasedGameHeader()
          ) : (
            <StyledHeaderText
              variant="h2"
              sx={{
                paddingY: 1,
                margin: 0,
              }}
            >
              Game type
            </StyledHeaderText>
          )}
          {!isNonEditable && (
            <Alert sx={{ marginBottom: 1 }} severity="warning">
              Please read{' '}
              <a href={DOCS_URL} target="_blank" rel="noreferrer" style={{ color: 'white' }}>
                our CrazyGames documentation
              </a>{' '}
              carefully before submitting a game!
            </Alert>
          )}
          {!isCollapsed && this.renderEngineTypeSelect()}
          {!isCollapsed && this.renderGameOptions()}
          {!isCollapsed && this.renderGameEngineOptions()}
        </StyledContainer>
        <StyledContainer
          sx={{
            width: 900,
            m: 2,
            mt: 3,
            p: 2,
            px: 3,
          }}
        >
          <StyledHeaderText
            variant="h2"
            sx={{
              paddingY: 1,
              margin: 0,
            }}
          >
            Files
          </StyledHeaderText>
          {this.renderGameFilesSelect()}
          {this.renderCoverImageUpload()}
        </StyledContainer>
      </>
    );
  }

  private renderReleasedGameHeader() {
    const { isCollapsed } = this.state;
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <StyledHeaderText
          variant="h2"
          sx={{
            m: 0,
          }}
        >
          Game type
        </StyledHeaderText>
        {this.props.isNonEditable && (
          <StyledBodyText sx={{ m: 0.5, flex: 1, ml: 4 }} variant="bodyLower2" color="white80">
            You can't update this section. Feel free to <Link to="/support">contact us</Link> if you have any question.
          </StyledBodyText>
        )}
        {isCollapsed ? (
          <Add
            fontSize="large"
            sx={{ '&:hover': { cursor: 'pointer' } }}
            onClick={() =>
              this.setState({
                isCollapsed: false,
              })
            }
          />
        ) : (
          <Remove
            fontSize="large"
            sx={{ '&:hover': { cursor: 'pointer' } }}
            onClick={() =>
              this.setState({
                isCollapsed: true,
              })
            }
          />
        )}
      </div>
    );
  }

  private renderEngineTypeSelect() {
    const { gameEngineType } = this.props.filesForm;
    const { isNonEditable, attemptedToSave, fileFormProblems } = this.props;
    const typeError = attemptedToSave && fileFormProblems?.includes('GAME_ENGINE_TYPE_MISSING');
    return (
      <>
        <TypeSelect
          error={typeError}
          gameEngineType={gameEngineType || undefined}
          onGameEngineTypeChange={this.handleGameEngineTypeChange}
          disabled={isNonEditable}
        />
        {typeError && (
          <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
            {`Please select a type`}
          </StyledBodyText>
        )}
      </>
    );
  }

  private handleGameEngineTypeChange = (gameEngineType: GameEngineType) => {
    this.props.updateFilesForm({
      ...this.props.filesForm,
      gameEngineType,
      // reset APS/SDKPS data when changing engine type so we don't submit with wrong data
      progressSaveType: 'UNKNOWN',
      unitySaveFileName: '',
    });
  };

  private handleIsFullscreenableChange = (_evt: {}, isFullscreenable: boolean) => {
    this.props.updateFilesForm({ ...this.props.filesForm, isFullscreenable });
  };

  private renderGameOptions() {
    const { theme, isNonEditable } = this.props;
    const { gameEngineType, isIOSFriendly, isAndroidFriendly, isFullscreenable, hasIAP, isChromebookFriendly } = this.props.filesForm;
    const mustDisplayUnityIOsWarning = gameEngineType?.toLowerCase().includes('unity') && isIOSFriendly;

    if (gameEngineType) {
      return (
        <>
          <StyledBodyText variant="h3" sx={{ mt: 3, mb: 0 }}>
            Game Options
          </StyledBodyText>
          <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: theme.spacing() }}>
            <FormControlLabel
              control={<Checkbox checked={isFullscreenable} onChange={this.handleIsFullscreenableChange} />}
              label="The game works well in fullscreen"
              sx={{ color: COLORS.black[10] }}
              disabled={isNonEditable}
            />
            <FormControlLabel
              control={<Checkbox checked={isIOSFriendly} onChange={this.handleIsIOSFriendlyChange} />}
              label="The game works well on iOS devices' mobile web browsers"
              disabled={isNonEditable}
              sx={{ color: COLORS.black[10] }}
            />
            {mustDisplayUnityIOsWarning && (
              <Alert sx={{ marginBottom: 1 }} severity="info">
                Unity games will be disabled on iOS initially. Our team will evaluate after launch and enable the game if it works well
              </Alert>
            )}
            <FormControlLabel
              control={<Checkbox checked={isAndroidFriendly} onChange={this.handleIsAndroidFriendlyChange} />}
              label="The game works well on Android devices' mobile web browsers"
              disabled={isNonEditable}
              sx={{ color: COLORS.black[10] }}
            />
            <FormControlLabel
              control={<Checkbox checked={isChromebookFriendly} onChange={this.handleIsChromebookFriendlyChange} />}
              label="The game works well on Chromebooks"
              disabled={isNonEditable}
              sx={{ color: COLORS.black[10] }}
            />
            <FormControlLabel
              control={<Checkbox checked={hasIAP} onChange={this.handleHasIAPChange} />}
              label="The game offers in-game purchases"
              disabled={isNonEditable}
              sx={{ color: COLORS.black[10] }}
            />
            {hasIAP && (
              <Alert sx={{ marginBottom: 1 }} severity="warning">
                In-game purchases are only allowed for selected games and should use CrazyGames' Xsolla account/integration. Your game might
                be rejected if this does not apply to your game.
              </Alert>
            )}
          </div>
          {this.renderMobileOrientation()}
        </>
      );
    }
    return null;
  }

  private renderMobileOrientation() {
    const { isIOSFriendly, isAndroidFriendly, orientation } = this.props.filesForm;
    const { fileFormProblems, attemptedToSave, isNonEditable } = this.props;
    const orientationError = attemptedToSave && fileFormProblems?.includes('ORIENTATION_MISSING');

    return (
      (isIOSFriendly || isAndroidFriendly) && (
        <>
          <StyledBodyText variant="h3" sx={{ mt: 3, mb: 1.25 }}>
            Screen orientation on mobile <span>*</span>
          </StyledBodyText>
          <OrientationSelector
            disabled={isNonEditable}
            error={orientationError}
            defaultValue={orientation}
            onChange={this.handleOrientationChange}
          />
          <StyledBodyText sx={{ m: 0.5, height: 20 }} variant="bodyLower2" color="error">
            {orientationError && `Please select a orientation`}
          </StyledBodyText>
          <StyledBodyText variant="bodyLower" sx={{ marginY: 1.25 }} color="white30">
            Note: the orientation locking will be managed by us.
          </StyledBodyText>
        </>
      )
    );
  }

  private handleIsIOSFriendlyChange = (_evt: {}, isIOSFriendly: boolean) => {
    this.props.updateFilesForm({ ...this.props.filesForm, isIOSFriendly });
  };

  private handleIsAndroidFriendlyChange = (_evt: {}, isAndroidFriendly: boolean) => {
    this.props.updateFilesForm({ ...this.props.filesForm, isAndroidFriendly });
  };

  private handleIsChromebookFriendlyChange = (_evt: {}, isChromebookFriendly: boolean) => {
    this.props.updateFilesForm({ ...this.props.filesForm, isChromebookFriendly });
  };

  private handleHasIAPChange = (_evt: {}, hasIAP: boolean) => {
    this.props.updateFilesForm({ ...this.props.filesForm, hasIAP });
  };

  private handleOrientationChange = (orientation: string | null) => {
    this.props.updateFilesForm({ ...this.props.filesForm, orientation: orientation as GAME_ORIENTATION | null });
  };

  private renderUploadInstructions() {
    const { instructionsDialogOpen } = this.state;
    return (
      <div>
        <StyledButton onClick={this.toggleInstructions} height={22}>
          <HelpIcon />
        </StyledButton>
        <UploadInstructions open={instructionsDialogOpen} onClose={this.toggleInstructions} />
      </div>
    );
  }

  private toggleInstructions = () => {
    const { instructionsDialogOpen } = this.state;
    this.setState({
      instructionsDialogOpen: !instructionsDialogOpen,
    });
  };

  private renderGameEngineOptions() {
    const { gameEngineType, unity56Encoding, unitySaveFileName, progressSaveType } = this.props.filesForm;
    const { isNonEditable } = this.props;
    return (
      <>
        <GameDataSaveOptions value={progressSaveType} onChange={this.onGameDataSaveChange} isNonEditable={isNonEditable} />
        {progressSaveType === 'CUSTOM' && (
          <UnitySaveFileName
            gameEngineType={gameEngineType}
            fileName={unitySaveFileName}
            onChange={this.handleSaveFileNameChange}
            isNonEditable={isNonEditable}
          />
        )}
        <UnityCompressionOption
          gameEngineType={gameEngineType}
          unity56Encoding={unity56Encoding}
          files={this.props.filesForm.files}
          onChange={this.handleUnityEncodingChange}
          isNonEditable={this.props.isNonEditable}
        />
        <GameMultiplayerOptions />
      </>
    );
  }

  private onGameDataSaveChange = (progressSaveType: ProgressSaveType) => {
    const updatedFilesForm: FilesFormData = { ...this.props.filesForm, progressSaveType };
    if (progressSaveType !== 'CUSTOM') {
      updatedFilesForm.unitySaveFileName = '';
    }
    this.props.updateFilesForm(updatedFilesForm);
  };

  private renderGameFilesSelect() {
    const { gameEngineType } = this.props.filesForm;
    if (gameEngineType === 'IFRAME') {
      return this.renderIframeInput();
    } else {
      return this.renderGameFilesUpload();
    }
  }

  private renderIframeInput() {
    const { iframeLink } = this.props.filesForm;
    const { fileFormProblems, attemptedToSave } = this.props;
    const isHttp = !!iframeLink && iframeLink.startsWith('http://');
    const httpHelperText = isHttp
      ? 'Preview will not work for HTTP, if you support HTTPS please use that link instead.'
      : 'http(s)://example.com';

    const iframeError = (attemptedToSave && fileFormProblems?.includes('IFRAME_INVALID_URL')) || isHttp;

    return (
      <>
        <StyledBodyText variant="h3" sx={{ marginY: 1.25 }}>
          IFrame link
        </StyledBodyText>
        <StyledInput
          required
          type="url"
          value={iframeLink ? iframeLink : ''}
          onChange={this.handleIframeLinkChange}
          error={iframeError}
          disabled={this.props.isReleased}
        />
        <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="black10">
          {httpHelperText}
        </StyledBodyText>
      </>
    );
  }

  private handleUnityEncodingChange = (encoding: FilesFormData['unity56Encoding']) => {
    this.props.updateFilesForm({ ...this.props.filesForm, unity56Encoding: encoding });
  };

  private handleSaveFileNameChange = (newFileName: string) => {
    this.props.updateFilesForm({ ...this.props.filesForm, unitySaveFileName: newFileName });
  };

  private handleIframeLinkChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.props.updateFilesForm({ ...this.props.filesForm, iframeLink: valueFromInput(evt) });
  };

  private renderGameFilesUpload() {
    const { theme, attemptedToSave, fileFormProblems, isNonEditable } = this.props;
    const { files, gameEngineType } = this.props.filesForm;

    const uploadMissingError =
      attemptedToSave && (fileFormProblems?.includes('HTML5_FILES_MISSING') || fileFormProblems?.includes('UNITY_FILES_MISSING'));

    const uploadingError = attemptedToSave && fileFormProblems?.includes('GAME_FILE_UPLOAD_IN_PROGRESS');

    const limitsExceededError =
      fileFormProblems?.includes('TOTAL_FILE_COUNT_EXCEEDED') || fileFormProblems?.includes('TOTAL_FILE_SIZE_EXCEEDED');

    return (
      <>
        <div style={{ display: 'flex', margin: theme.spacing(2, 0, 1.25, 0) }}>
          <StyledBodyText variant="h3" sx={{ m: 0 }}>
            Files Upload <span>*</span>
          </StyledBodyText>
          {this.renderUploadInstructions()}
        </div>
        <GameFilesUpload
          initialFiles={files || []}
          error={uploadMissingError || uploadingError || limitsExceededError}
          gameType={gameEngineType ? gameEngineType : undefined}
          onUploadsStarted={this.handleUploadStarted}
          onFilesModified={this.handleUploadCompleted}
          isNonEditable={isNonEditable}
        />
        {uploadMissingError && renderHardWarning(`Please upload your files`)}
        {uploadingError && renderHardWarning(`Please wait until Game file upload is complete`)}
        {limitsExceededError &&
          renderHardWarning(
            <>
              Maximum number of files: {MAX_TOTAL_FILES_COUNT}. Maximum total size: {MAX_TOTAL_FILE_SIZE_MB}MB (less than{' '}
              {TOTAL_FILE_SIZE_DESKTOP_WARNING_MB}MB recommended for best performance). Check the Resources section from{' '}
              <a href={DOCS_URL} target="_blank" rel="noreferrer">
                our documentation
              </a>{' '}
              for your specific engine to find tips about optimizing your game.
            </>,
          )}
        {!limitsExceededError && this.renderSoftLimitWarning()}
      </>
    );
  }

  private renderSoftLimitWarning() {
    const isMobileFriendly = this.props.filesForm.isAndroidFriendly || this.props.filesForm.isIOSFriendly;
    const softWarnings = validateFileLimitsSoft(isMobileFriendly, this.props.filesForm.files || []);
    const elements: JSX.Element[] = [];

    if (softWarnings.includes('FILE_COUNT_TOO_BIG')) {
      elements.push(
        renderSoftWarning(
          `Your game has a lot of files and we might disable it in case of high load times. Reduce the number of files below ${TOTAL_FILE_COUNT_WARNING} for a better user experience.`,
        ),
      );
    }

    if (softWarnings.includes('TOTAL_FILE_SIZE_TOO_BIG_DESKTOP')) {
      elements.push(
        renderSoftWarning(
          `Your game is large and we might disable it in case of high load times. Reduce the file size under ${TOTAL_FILE_SIZE_DESKTOP_WARNING_MB}MB for a better user experience.`,
        ),
      );
    }

    if (softWarnings.includes('TOTAL_FILE_SIZE_TOO_BIG_MOBILE')) {
      elements.push(
        renderSoftWarning(
          `Your game is large and we might disable it on mobile in case of high load times. Reduce the file size under ${TOTAL_FILE_SIZE_MOBILE_WARNING_MB}MB for a better user experience.`,
        ),
      );
    }

    if (softWarnings.length > 0) {
      elements.push(
        renderSoftWarning(
          <>
            Check the Resources section from{' '}
            <a href={DOCS_URL} target="_blank" rel="noreferrer">
              our documentation
            </a>{' '}
            for your specific engine to find tips about optimizing your game.
          </>,
        ),
      );
    }

    return elements;
  }

  private handleUploadStarted = () => {
    this.props.updateFilesForm({ ...this.props.filesForm, uploadInProgress: true });
  };

  private handleUploadCompleted = (files: UploadedFile[]) => {
    this.props.updateFilesForm({ ...this.props.filesForm, uploadInProgress: false, files });
  };

  private renderCoverImageUpload() {
    const { fileFormProblems, attemptedToSave, isNonEditable } = this.props;
    const coverError16x9 = attemptedToSave && fileFormProblems?.includes('COVER_IMAGE_MISSING16x9');
    const coverError2x3 = attemptedToSave && fileFormProblems?.includes('COVER_IMAGE_MISSING2x3');
    const coverError1x1 = attemptedToSave && fileFormProblems?.includes('COVER_IMAGE_MISSING1x1');
    const anyCoverError = coverError16x9 || coverError1x1 || coverError2x3;

    const errors = {
      '16x9': !!coverError16x9,
      '2x3': !!coverError2x3,
      '1x1': !!coverError1x1,
    };

    return (
      <>
        <StyledBodyText variant="h3" sx={{ mt: 3, mb: 1.25 }}>
          Cover Images <span>*</span>
        </StyledBodyText>
        <StyledBodyText variant="bodyLower" color="white30" sx={{ mb: 3 }}>
          We will use the cover image to show your game on our pages (homepage, category pages, …). Make it appealing and professional
          looking! A good cover image will make the users want to play your game. Please provide 3 sizes for your game cover : landscape
          (1920x1080) portrait (800x1200) and square (800x800). For more information, make sure to read our{` `}
          <a href={`${DOCS_URL}/requirements/game-covers/`} target="_blank" rel="noreferrer">
            guidelines for game covers
          </a>
          .
        </StyledBodyText>

        <SubmissionUpdateGameCovers
          gameCovers={this.props.filesForm.gameCovers}
          errors={errors}
          onChange={this.handleCoverImageChanged}
          isNonEditable={isNonEditable}
        />
        {anyCoverError && (
          <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
            {`Please upload a cover image for each aspect ratio`}
          </StyledBodyText>
        )}
      </>
    );
  }

  private handleCoverImageChanged = (coverId: GAME_COVERS_RESOLUTIONS, file: UploadedFile | null) => {
    const currentForm = this.props.filesForm;
    this.props.updateFilesForm({
      ...currentForm,
      gameCovers: { ...currentForm.gameCovers, [coverId]: file } as GameCoversWithUploadedFile,
    });
  };
}

export default withTheme(withSubmission(GameFilesForm));
