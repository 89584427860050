import { UploadedFile } from '../../../../../../common/domain/upload';
import { GameEngineType, unityEnginesWithDataSave } from '../../../../../../common/domain/game';
import { isTooLongStringInput, isValidUrl } from '../../../../../../common/validations';
import { Unity56Encoding } from './UnityEngineOptions/UnityCompressionSelect';
import { FilesFormProblem, FilesFormSoftWarnings } from '../../SubmitGame/submission-errors';
import { GAME_ORIENTATION } from '../../../../../../common/CzyForm/OrientationSelector';
import { GAME_COVERS_RESOLUTIONS, GameCoversWithUploadedFile, buildEmptyGameCovers } from '../../../../../../common/graphql/games/game';
import { MOBILE_FRIENDLY_GAME_ENGINES } from '../../../../../../common/game-technology';

export const MAX_NO_TAGS = 5;

// developer cannot submit if total size > 250MB or if there are more than 1500 files, however these hard warnings aren't applied when updating
export const MAX_TOTAL_FILE_SIZE_MB = 250;
export const MAX_TOTAL_FILES_COUNT = 1500;

// these are for the soft warnings (yellow warnings), that allow you to submit
export const TOTAL_FILE_SIZE_MOBILE_WARNING_MB = 20;
export const TOTAL_FILE_SIZE_DESKTOP_WARNING_MB = 50;
export const TOTAL_FILE_COUNT_WARNING = 200;

export type ProgressSaveType =
  | 'UNKNOWN' // old submissions without completed data save toggles
  | 'PLAYERPREFS' // unity submissions storing data just in player prefs
  | 'CUSTOM' // unity submission, storing data in a custom file. This will also ask the devs for a file name.
  | 'LOCALSTORAGE' // html5 submissions storing data in localStorage
  | 'NOTNEEDED' // The game does not need progress save
  | 'NO' // the game doesn't store any progress
  | 'INGAMEAUTOLOGIN' // same thing as INGAME, but also offers auto login
  | 'SDKPS'; // for HTML5 games that use the data module from our HTML5 SDK to store data

export interface MultiplayerOptions {
  isMultiplayer?: boolean;
  supportsInstantJoin?: boolean;
  hasModeratedChat?: boolean;
  minLobbySize?: number;
  maxLobbySize?: number;
}

export interface FilesFormData {
  gameEngineType: GameEngineType | null;
  isFullscreenable: boolean;
  isIOSFriendly: boolean;
  isAndroidFriendly: boolean;
  orientation: GAME_ORIENTATION | null;
  iframeLink: string | null;
  files: UploadedFile[] | null;
  gameCovers: GameCoversWithUploadedFile;
  uploadInProgress: boolean;
  unity56Encoding?: Unity56Encoding;
  unitySaveFileName?: string;
  progressSaveType?: ProgressSaveType;
  hasIAP: boolean;
  isChromebookFriendly: boolean;
  multiplayerOptions?: MultiplayerOptions;
}

export function buildDefaultFilesFormData(): FilesFormData {
  return {
    gameEngineType: null,
    isFullscreenable: false,
    isIOSFriendly: false,
    isAndroidFriendly: false,
    orientation: null,
    iframeLink: null,
    files: null,
    gameCovers: buildEmptyGameCovers(),
    uploadInProgress: false,
    unity56Encoding: 'brotli',
    progressSaveType: 'UNKNOWN',
    hasIAP: false,
    isChromebookFriendly: false,
    multiplayerOptions: undefined,
  };
}

export function validateFilesForm(form: FilesFormData, validateFileLimits?: boolean): FilesFormProblem[] {
  const problems: FilesFormProblem[] = [
    ...validateCoverImages(form.gameCovers),
    ...validateGameEngineType(form),
    ...validateOrientation(form),
    ...validateMultiplayerLobbySize(form.multiplayerOptions),
  ];

  if (validateFileLimits) {
    problems.push(...validateFileLimitsHard(form.files || []));
  }

  return problems;
}

export function validateOrientation(form: FilesFormData): FilesFormProblem[] {
  const { gameEngineType, isAndroidFriendly, isIOSFriendly, orientation } = form;

  if (!gameEngineType || !MOBILE_FRIENDLY_GAME_ENGINES.includes(gameEngineType)) {
    return [];
  }

  if (!isAndroidFriendly && !isIOSFriendly) {
    return [];
  }

  if (!orientation) {
    return ['ORIENTATION_MISSING'];
  }

  return [];
}

export function validateFileLimitsSoft(mobileFriendly: boolean, files: UploadedFile[]): FilesFormSoftWarnings[] {
  const warnings: FilesFormSoftWarnings[] = [];
  const totalSize = files.reduce((acc, file) => acc + file.size, 0);

  if (files.length > TOTAL_FILE_COUNT_WARNING) {
    warnings.push('FILE_COUNT_TOO_BIG');
  }

  if (mobileFriendly && totalSize > TOTAL_FILE_SIZE_MOBILE_WARNING_MB * 1024 * 1024) {
    warnings.push('TOTAL_FILE_SIZE_TOO_BIG_MOBILE');
  }

  if (!mobileFriendly && totalSize > TOTAL_FILE_SIZE_DESKTOP_WARNING_MB * 1024 * 1024) {
    warnings.push('TOTAL_FILE_SIZE_TOO_BIG_DESKTOP');
  }

  return warnings;
}

export function validateFileLimitsHard(files: UploadedFile[]): FilesFormProblem[] {
  const problems: FilesFormProblem[] = [];
  const totalSize = files.reduce((acc, file) => acc + file.size, 0);

  if (files.length > MAX_TOTAL_FILES_COUNT) {
    problems.push('TOTAL_FILE_COUNT_EXCEEDED');
  }

  if (totalSize > MAX_TOTAL_FILE_SIZE_MB * 1024 * 1024) {
    problems.push('TOTAL_FILE_SIZE_EXCEEDED');
  }

  return problems;
}

function validateMultiplayerLobbySize(multiplayerOptions: MultiplayerOptions | undefined): FilesFormProblem[] {
  const problems: FilesFormProblem[] = [];

  // if at least one of the lobby size options is set, both must be correctly set
  if (
    (multiplayerOptions?.minLobbySize !== undefined && multiplayerOptions?.minLobbySize !== null) ||
    (multiplayerOptions?.maxLobbySize !== undefined && multiplayerOptions?.maxLobbySize !== null)
  ) {
    if (
      typeof multiplayerOptions?.minLobbySize !== 'number' ||
      typeof multiplayerOptions?.maxLobbySize !== 'number' ||
      multiplayerOptions?.maxLobbySize < multiplayerOptions?.minLobbySize
    ) {
      problems.push('MULTIPLYAER_INVALID_LOBBY');
    }

    if (typeof multiplayerOptions?.minLobbySize === 'number' && multiplayerOptions?.minLobbySize < 2) {
      problems.push('MULTIPLYAER_INVALID_LOBBY_MIN');
    }
  }
  return problems;
}

function validateCoverImages(coverImages: GameCoversWithUploadedFile | null): FilesFormProblem[] {
  const problems: FilesFormProblem[] = [];
  if (coverImages === null) {
    problems.push('COVER_IMAGES_MISSING');
  } else {
    for (const coverId in coverImages) {
      if (!coverImages[coverId as GAME_COVERS_RESOLUTIONS]) {
        problems.push(`COVER_IMAGE_MISSING${coverId}` as FilesFormProblem);
      }
    }
  }
  return problems;
}

function validateGameEngineType(form: FilesFormData): FilesFormProblem[] {
  const { gameEngineType, uploadInProgress } = form;
  if (gameEngineType === null) {
    return ['GAME_ENGINE_TYPE_MISSING'];
  }
  if (gameEngineType === 'IFRAME') {
    return validateIframeForm(form);
  }
  if (uploadInProgress) {
    return ['GAME_FILE_UPLOAD_IN_PROGRESS'];
  }
  switch (gameEngineType) {
    case 'HTML5':
      return validateHTML5Form(form);
    case 'UNITY_5_4':
    case 'UNITY_5_5':
    case 'UNITY_5_6':
    case 'UNITY_2017':
    case 'UNITY_2018':
    case 'UNITY_2019':
    case 'UNITY_2020':
    case 'UNITY_2021':
    case 'UNITY_2022':
    case 'UNITY_2023':
    case 'UNITY_6':
      return validateUnityForm(form);
    default:
      throw new Error(`Unmapped game engine type: ${gameEngineType}`);
  }
}

function validateHTML5Form(form: FilesFormData): FilesFormProblem[] {
  const { files } = form;
  const problems: FilesFormProblem[] = [];
  if (!files || !hasFilePattern(files, /\/?index.html$/)) {
    problems.push('HTML5_FILES_MISSING');
  }
  if (!form.progressSaveType || form.progressSaveType === 'UNKNOWN') {
    problems.push('APS_INFO_MISSING');
  }
  return problems;
}

function validateIframeForm(form: FilesFormData): FilesFormProblem[] {
  const { iframeLink } = form;
  const problems: FilesFormProblem[] = [];
  if (iframeLink === null || isTooLongStringInput(iframeLink) || !isValidUrl(iframeLink)) {
    problems.push('IFRAME_INVALID_URL');
  }
  if (!form.progressSaveType || form.progressSaveType === 'UNKNOWN') {
    problems.push('APS_INFO_MISSING');
  }
  return problems;
}

function validateUnityForm(form: FilesFormData): FilesFormProblem[] {
  const { files } = form;
  const problems: FilesFormProblem[] = [];
  if (!files || files.length < 1) {
    problems.push('UNITY_FILES_MISSING');
  }
  if (form.gameEngineType && unityEnginesWithDataSave.includes(form.gameEngineType)) {
    if (!form.progressSaveType || form.progressSaveType === 'UNKNOWN') {
      problems.push('APS_INFO_MISSING');
    }
    if (form.progressSaveType === 'CUSTOM') {
      if (!form.unitySaveFileName || form.unitySaveFileName?.length === 0) {
        problems.push('APS_UNITY_CUSTOM_NAME_MISSING');
      } else if (isTooLongStringInput(form.unitySaveFileName)) {
        problems.push('APS_UNITY_CUSTOM_NAME_INVALID');
      }
    }
  }
  return problems;
}

function hasFilePattern(files: UploadedFile[], rx: RegExp) {
  return files.find(({ path }) => rx.test(path)) !== null;
}
